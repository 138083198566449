.body {
  background: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main {
  flex: 1;
  padding-top: 72px;
  min-height: 100vh;
}

.navigation {
  box-shadow: 0 -1px 4px 0 rgba(106, 106, 106, 0.5);
  background-color: #ffffff;
  position: fixed;
  z-index: 1000;
  width: 100%;
}
